.main p{
    color: #FED463;
    font-size: 5vw;
    text-align: center;
}
.main{
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
}
