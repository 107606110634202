.company{
    display: flex;
    flex-direction: row;
    padding-top: 40px;
}
.company-title{
    color: #FED463;
    font-weight: bold;
    font-size: 2vw;
}
.company-img{
    width: 10vw;
}
.companies-group{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.company-text{
    width: 50vw;
    margin-left: 20px;
}
.company-desc{
    color: #FED463;
    font-size: 1.5vw;
}
.company-site{
    color: white;
    font-size: 1.5vw;
    padding-top: 7px;
    font-weight: bold;
}
@media (max-width: 1010px) {
    .company-site{
        font-size: 3.5vw;

    }
    .company-desc{
        font-size: 3.5vw;
    }
    .company-title{
        font-size: 4vw;
    }
    .company-img{
        width: 128px;
        height: 140px;
    }
}