.title-aboutClub{
    font-size: 30px;
    color:#FED463;
    text-align: center;
}
.text-aboutClub{
    color: white;
    font-size: 20px;
    padding-top: 15px;
    text-align: center;
}
.texts-aboutClub{
    padding-top: 30px;
    width: 90vw;
    padding-bottom: 70px;
}
.all-aboutClub{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}