.title-IPS{
    font-size: 30px;
    color:#FED463
}
.link-IPS{
    color: white;
    font-size: 20px;
    padding-top: 15px;
}
.link-IPS:hover{
    color: #FED463;
}

.item-IPS{
    padding-top: 60px;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
}
.item-IPS:last-child{
    padding-bottom: 50px;
}
@media(max-width: 1010px) {
    .link-IPSP{
    font-size:18px
    }
    .title-IPS{
        font-size: 25px;
    }
}