.first-image{
    max-height: 60vh;
    max-width: 100vw;
}
.first-image-cont{
    width: 100vw;
    display: flex;
    justify-content: center;
}
.images img{
    width: 45vw;
    margin-left: 5px;
    margin-right: 5px;
}
.images{
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}
.item-tao{
    padding: 0 10px 15px 10px;
    color: #FED463;
    text-align: center;
    max-width: 90vw;
}
.title-tao{
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 25px;
}
.text-tao{
    font-size: 20px;
}
@media (max-width: 1010px){
    .title-tao{
        font-size: 20px;
    }
    .text-tao{
        font-size: 18px;
    }
}