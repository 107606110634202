.item-img{
    width: 45vw;
}
.desc{
    position: relative;
    bottom: 2.7vw;
    padding-bottom: 3px;
    background-color: rgba(29, 28, 55, 0.78);
    text-align: center;
    width: 45vw;
}
.projects{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 3vw;
    min-height: 100vh;
}

.item{
    padding: 0;
    margin-left: 1vw;
}
.desc-a:hover{
    color: #FED463;
}
.desc-a{
    color: white;
    font-size: 2vw;
}

@media (max-width: 1016px){
    .desc-a{
        color: white;
        font-size: 4vw;
    }
    .item-img{
        width: 96vw;
    }
    .item{
        margin-left: 0;
    }
    .projects{
        margin-left: 1vw;
    }
    .desc{
        width: 96vw;
        bottom: 5.3vw;
    }
}