@font-face {
  font-family: "CaviarDreamsRegular";
  src: url("./fonts/CaviarDreamsRegular/CaviarDreams-Regular.eot");
  src: url("./fonts/CaviarDreamsRegular/CaviarDreams-Regular.eot?#iefix")format("embedded-opentype"),
  url("./fonts/CaviarDreamsRegular/CaviarDreams-Regular.woff") format("woff"),
  url("./fonts/CaviarDreamsRegular/CaviarDreams-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
*{
  font-family: 'CaviarDreamsRegular';
  margin: 0;
  padding: 0;
}

.App {
  background-color: #1d1c37;
  width: 100vw;
  min-height: 100vh;
}
* a{
  text-decoration: none;
}
body{

  overflow-x: hidden;
}