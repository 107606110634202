.contacts-title{
    font-size: 36px;
    font-weight: bold;
}
.contacts-text{
    padding-top: 30px;
    margin-bottom: 20px;
    justify-content: center;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FED463;
    text-align: center;
}
.contacts-desc{
    padding-top: 10px;
    font-size: 26px;
    padding-bottom: 10px;
    max-width: 80vw;
}
.contacts{
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 10px;
    max-width: 80vw;
}
.contacts p{
    padding-bottom: 5px;
    font-size: 20px;
}
.location{
    font-size: 23px;
    max-width: 80vw;
}
.contacts a{
    color: white;
    font-size: 26px;
}
a:hover{
    color: #FED463;
}