.phone-photo{
    display: none;
}
.header img{
    width: 25vw;
}
.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 20px;
}
.name{
    font-size: 3vw;
    color: #FED463;
    text-align: center;
}
.header h3{
    padding-top: 0px;
    color: #FED463;
    font-size: 2vw;
}
.item{
    padding-bottom: 15px;
    color: #FED463;
}
.title{
    padding-bottom: 2.5px;
    padding-top: 2.5px;
    font-size: 20px;
}
.texts{
    width: 90vw;
    display: flex;
    flex-direction: column;
    padding-top: 2vw;
    padding-bottom: 70px;
}
.Irina{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.text{
    font-size: 20px;
}
.texts h3{
    padding-top: 40px;
}
@media (max-width: 1010px){
    .header img{
        width: 50vw;
    }
    .header h3{
        font-size: 4vw;
    }
    .name{
        font-size: 6vw;
    }
    .title{
        font-size: 17px;
    }
    .desc-photo{
        display: none;
    }
    .phone-photo{
        display: block;
    }
}